app-generator {
  #lengthRange {
    width: 100%;
  }

  .card-generated {
    .card-body {
      @include themify($themes) {
        background: themed("foregroundColor");
      }
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-family: $font-family-monospace;
      font-size: $font-size-lg;
      justify-content: center;
      text-align: center;
    }
  }
}

app-password-generator-history {
  .list-group-item {
    line-height: 1;
    @include themify($themes) {
      background: themed("backgroundColor");
    }

    .password {
      font-family: $font-family-monospace;
    }
  }
}

app-import {
  textarea {
    height: 150px;
  }
}

app-avatar {
  img {
    @extend .rounded;
  }
}

app-user-billing {
  .progress {
    height: 20px;

    .progress-bar {
      min-width: 50px;
    }
  }
}

app-sponsored-families {
  .inset-list {
    padding-left: 1.5rem;
  }
}

/* Register Layout Page */
.layout {
  &.default,
  &.teams,
  &.teams1,
  &.teams2,
  &.enterprise,
  &.enterprise1,
  &.enterprise2,
  &.cnetcmpgnent,
  &.cnetcmpgnteams,
  &.cnetcmpgnind {
    header {
      background: #175ddc;
      color: #ced4da;
      height: 70px;

      &:before {
        background: #175ddc;
        content: "";
        height: 520px;
        left: 0;
        position: absolute;
        top: -80px;
        transform: skewY(-3deg);
        width: 100%;
        z-index: -1;
      }
      img.logo {
        height: 57px;
        margin: 12px 0 0;
        max-width: 284px;
        width: 284px;
      }
    }

    h1 {
      color: #ffffff;
      font-size: 3.5rem;
      margin: 50px 0 0;
    }

    h2 {
      color: #ffffff;
      font-size: 2rem;
      line-height: 1.5;
      margin: 20px 0 140px;
    }

    p {
      font-size: 2rem;
      margin: 10px 0 70px 0;

      &:before {
        content: "/";
        padding-right: 12px;
      }
      &:not(.highlight) {
        &:before {
          color: #1252a3;
        }
      }

      b {
        &:after {
          content: "⟶";
          font-size: 2rem;
          padding-left: 6px;
        }
      }
    }

    figure {
      margin: 0;
    }

    blockquote {
      font-size: 1.4rem;
      margin: 20px 0 0;
    }
  }

  &.cnetcmpgnind {
    p {
      font-size: 1.5rem;
      margin: 10px 0 50px 0;
    }
  }
}

.collapsable-row {
  display: flex;
  padding-top: 15px;
  i {
    margin-top: 3px;
  }
  .filter-title {
    padding-left: 5px;
  }
  &.active {
    @include themify($themes) {
      color: themed("primary");
    }
  }
}

.vault-filter-option {
  padding-bottom: 3px;
  &.active {
    @include themify($themes) {
      color: themed("primary");
      font-weight: bold;
    }
  }
  button.org-options {
    background: none;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      @include themify($themes) {
        color: themed("iconHover") !important;
        box-shadow: none;
      }
    }
  }
}

.org-filter-heading {
  @include themify($themes) {
    color: themed("textColor");
  }
  &.active {
    @include themify($themes) {
      color: themed("primary");
      font-weight: bold;
    }
  }
}
